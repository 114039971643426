/* eslint-disable */

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63553f7f4f3fdf4d450fd417"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class FaqView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("..controllers/FaqController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = FaqView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "63701ac60d7d56e4408cc8b8";
    htmlEl.dataset["wfSite"] = "63553f7f4f3fdf4d450fd417";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      FaqView.Controller !== FaqView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
        //   dangerouslySetInnerHTML={{
        //     __html: `
        //   @import url(C:\\css\\normalize.css);
        //   @import url(C:\\css\\webflow.css);
        //   @import url(C:\\css\\restia.webflow.css);
        // `,
        //   }}
        />
        <span className="af-view">
          <div>
            <img
              src="images/path4722.svg"
              alt="background"
              title=""
              loading="lazy"
              className="header-pic"
            />
            <Header title={"Často kladené otázky"} selectedPage={"FAQ"} />
            <div className="faq-select wf-section">
              <div className="container faq-select-container w-container">
                <div className="categories">
                  <div className="categories-buttons">
                    <a
                      href="#"
                      className="button button-simple button-simple-big button-simple-big-selected w-button"
                    >
                      Online objednávky
                    </a>
                    <a
                      href="#"
                      className="button button-simple button-simple-big w-button"
                    >
                      Dispečink
                    </a>
                    <a
                      href="#"
                      className="button button-simple button-simple-big w-button"
                    >
                      Web
                    </a>
                  </div>
                </div>
                <div className="subcategories">
                  <div className="subcategories-buttons">
                    <a
                      href="#"
                      className="button button-simple button-simple-small w-button"
                    >
                      Všeobecné
                    </a>
                    <a
                      href="#"
                      className="button button-simple button-simple-small button-simple-small-selected w-button"
                    >
                      Menu Manager
                    </a>
                    <a
                      href="#"
                      className="button button-simple button-simple-small w-button"
                    >
                      Automatizace
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <section className="faq-section">
              <div className="container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-accordion">
                      <div
                        data-w-id="d37dc474-8a0e-8efc-172c-48f4ef189ca7"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text faq-question-text-full">
                            Načtení položek z kasy
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique. Duis cursus, mi quis viverra ornare, eros
                            dolor interdum nulla, ut commodo diam libero vitae
                            erat. Aenean faucibus nibh et justo cursus id rutrum
                            lorem imperdiet. Nunc ut sem vitae risus tristique
                            posuere.
                          </p>
                          <img
                            src="images/tablet.png"
                            loading="lazy"
                            srcSet="images/tablet-p-500.png 500w, images/tablet.png 628w"
                            sizes="(max-width: 479px) 50vw, (max-width: 767px) 231px, 274.828125px"
                            alt="prezentační video ukázka"
                            title="title_images/tablet.png"
                            className="faq-answer-image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="e2b930e7-2b42-715d-3f4b-7df8a9e65382"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text faq-question-text-full">
                            Úprava položek
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique. Duis cursus, mi quis viverra ornare, eros
                            dolor interdum nulla, ut commodo diam libero vitae
                            erat. Aenean faucibus nibh et justo cursus id rutrum
                            lorem imperdiet. Nunc ut sem vitae risus tristique
                            posuere.
                          </p>
                          <img
                            src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                            loading="lazy"
                            alt="alt_assets/placeholder.60f9b1840c.svg"
                            title="title_assets/placeholder.60f9b1840c.svg"
                            className="faq-answer-image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="5fad39ce-304f-389f-5771-8175b199c294"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text faq-question-text-full">
                            Rozdíly mezi platformami
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique. Duis cursus, mi quis viverra ornare, eros
                            dolor interdum nulla, ut commodo diam libero vitae
                            erat. Aenean faucibus nibh et justo cursus id rutrum
                            lorem imperdiet. Nunc ut sem vitae risus tristique
                            posuere.
                          </p>
                          <img
                            src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                            loading="lazy"
                            alt="alt_assets/placeholder.60f9b1840c.svg"
                            title="title_assets/placeholder.60f9b1840c.svg"
                            className="faq-answer-image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="83f2d679-7cf7-cb4f-0fca-91285f06bb61"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text faq-question-text-full">
                            Upload menu
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique. Duis cursus, mi quis viverra ornare, eros
                            dolor interdum nulla, ut commodo diam libero vitae
                            erat. Aenean faucibus nibh et justo cursus id rutrum
                            lorem imperdiet. Nunc ut sem vitae risus tristique
                            posuere.
                          </p>
                          <img
                            src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                            loading="lazy"
                            alt="alt_assets/placeholder.60f9b1840c.svg"
                            title="title_assets/placeholder.60f9b1840c.svg"
                            className="faq-answer-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default FaqView;

/* eslint-enable */
