import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProduktyView, IndexView } from "./views";
import KontaktView from "./views/KontaktView";
import ScrollToTop from "./ScrollToTop";
import ErrorPageView from "./views/ErrorPageView";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<IndexView />} />
          {/* <Route path="faq" element={<FaqView />} /> */}
          <Route path="produkty" element={<ProduktyView />} />
          <Route path="kontakt" element={<KontaktView />} />
          <Route path="*" element={<ErrorPageView />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
