/* eslint-disable */

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { transformProxies } from "./helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63553f7f4f3fdf4d450fd417"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class ProduktyView extends React.Component {
  constructor() {
    super();
    this.state = {
      accordionOpenIndex: null,
      lightbox: null,
    };
  }
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("..controllers/CenikController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = ProduktyView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "637042ef4679d85524409e3c";
    htmlEl.dataset["wfSite"] = "63553f7f4f3fdf4d450fd417";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      ProduktyView.Controller !== ProduktyView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <Helmet>
          <title>Produkty a funkce | Jeden systém pro všechno! | RESTIA</title>
          <meta
            name="description"
            content="RESTIA poskytuje 50+ funkcí, které vám usnadní chod vaší restaurace. Eshop restaurace ✔️ online objednavky na vašem webu ✔️ dispečink pro řízení rozvozu ✔️ aplikace pro kurýry ✔️ jeden tablet pro všechny platformy ✔️ menu manager ✔️ menu importer ✔️ šetří náklady ✔️ telefonní objednávky ✔️"
          />
          <meta
            name="keywords"
            content="RESTIA, objednávkový systém, eshop pro restaurace, aplikace pro řidiče, dispečink, menu manager, menu import, všechny objednávky na jednom místě, integrace s POS, wolt, bolt, foodora, damejidlo"
          />
        </Helmet>
        <style
        //   dangerouslySetInnerHTML={{
        //     __html: `
        //   @import url(C:\\css\\normalize.css);
        //   @import url(C:\\css\\webflow.css);
        //   @import url(C:\\css\\restia.webflow.css);
        // `,
        //   }}
        />
        <span className="af-view">
          <div>
            <img
              src="images/path4722.svg"
              alt="background"
              title=""
              loading="lazy"
              className="header-pic"
            />
            <Header title={"Produkty"} selectedPage={"produkty"} />
            <section
              className="hero-heading-left wf-section"
              id="online-objednavky"
            >
              <div className="container">
                <div className="hero-wrapper-3">
                  <div className="pricing-heading">
                    <h2 className="heading-2">RESTIA</h2>
                    <p className="margin-bottom-24px">
                    Intuitívny systém na správu všetkých online objednávok s možnosťou napojenia na dispečing. RESTIA vám pomôže s digitalizáciou vášho podniku pre 21. storočie, kde vám bude stačiť jedna aplikácia na kompletný chod reštaurácie.
                    </p>
                  </div>
                  <div className="pricing-heading">
                    <img
                      src="images/restia.webp"
                      loading="lazy"
                      alt="restia logo"
                      title="RESTIA"
                      className="service-image"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="faq-section">
              <div className="container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-description">
                      <div className="faq-question-right">
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                            RESTIA Lite
                          </h3>
                        </div>
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                            RESTIA Pro
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 0 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 0 ? null : 0,
                        })
                      }
                    >
                      <div
                        data-w-id="83f2d679-7cf7-cb4f-0fca-91285f06bb61"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Propojenie s POS
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Prijatú objednávku z online platformy už nie je nutné manuálne markovať. Automaticky sa prenesie do pokladne a tým odpadne nielen zdĺhavé markovanie, ale aj možná chyba, ktorá sa v takej rýchlosti neraz stane. Všetky údaje z objednávky sú samozrejmosťou na bločku a bone pre kuchára, obsluhu aj kuriéra.
                          </p>
                          <a
                            href="images/propojeni_s_POS.webp"
                            data-lightbox="screenshots"
                            data-title="Propojení s POS"
                          >
                            <img
                              src="images/propojeni_s_POS.webp"
                              loading="lazy"
                              alt="propojení s pokladním systémem POS"
                              title="Propojeno s pokladním systémem POS"
                              className="faq-answer-image"
                            />
                          </a>
                          {/* <img
														src="images/propojeni_s_POS.webp"
														loading="lazy"
														srcSet="images/online_objednavky2x-p-500.webp 500w, images/online_objednavky2x.webp 794w"
														sizes="(max-width: 479px) 100vw, (max-width: 767px) 231px, 211.15625px"
														alt
														className="faq-answer-image"
													/> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 1 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 1 ? null : 1,
                        })
                      }
                    >
                      <div
                        data-w-id="a81da464-d462-d056-e326-150fd98d4503"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">Menu Manager</div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Jedným kliknutím nahrajete Vaše menu do Wolt, Bolt, Foodory(Foodpanda) a dokonca aj na Váš web. Pokročilý Menu Manager vykoná všetky zmeny do 30 sekúnd a Vy budete mať istotu, že zákazník vždy vidí aktuálnu podobu menu. Ušetrený čas, ktorý by ste venovali úpravám na každej platforme investujte radšej do riadenia podniku. A ak sa bojíte, že úpravu menu vrátane prílohy nezvládnete, verte, že je to jednoduchšie, ako poslať email.
                          </p>
                          <a
                            href="images/menu_manager.webp"
                            data-lightbox="screenshots"
                            data-title="Menu Manager"
                          >
                            <img
                              src="images/menu_manager.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="menu manager menu importér správa menu"
                              title="Menu manager"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 2 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 2 ? null : 2,
                        })
                      }
                    >
                      <div
                        data-w-id="92947e65-c5bb-f9ad-66a7-9f3a500f43fc"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Napojenie online profilov
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>1</div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>neome&shy;zeně</div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Všetky objednávky z Woltu, Boltu, Foodory(Foodpanda) a Vašich webových stránok Vám ukážeme na jednom mieste - jednoducho, prehľadne a so všetkými informáciami o objednávke. Už nebudete potrebovať mať tablet pre každú platformu a okamžite uvidíte informácie o kuriérovi, ktorý k Vám práve ide alebo doručuje objednávku. Pri novej objednávke si môžete zadať vlastný čas prípravy, prípadne Vám náš autobot čas odporučí podľa aktuálnej vyťaženosti kuchyne.{" "}
                          </p>
                          <a
                            href="images/napojeni_online_profilu.webp"
                            data-lightbox="screenshots"
                            data-title="Napojení online profilů"
                          >
                            <img
                              src="images/napojeni_online_profilu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="všechny platformy na jednom místě bolt, wolt, foodora"
                              title="Všechny objednávky na jednom místě"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 3 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 3 ? null : 3,
                        })
                      }
                    >
                      <div
                        data-w-id="66202e47-30bc-eff6-4909-bd468ca14885"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Zapínanie/vypínanie položiek
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Vypredali ste nejaké jedlo, alebo Vám došla dôležitá surovina a potrebujete rýchlo upraviť ponuku? Vďaka našemu Menu Managerovi skryjete vypredané jedlo jedným kliknutím, aj v prípade, že ho máte v niekoľkých kategóriách. Druhý deň Vás tiež informujeme o skrytej položke a už sa vám nestane, že na ňu zabudnete a ďalších 14 dní sa nebude predávať.{" "}
                          </p>
                          <a
                            href="images/vypnuti_menu.webp"
                            data-lightbox="screenshots"
                            data-title="Zapínání/vypínání položek"
                          >
                            <img
                              src="images/vypnuti_menu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="skrytí a zobrazení položek na všech platformách"
                              title="Skrytí a zobrazení položek na všech platformách"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 4 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 4 ? null : 4,
                        })
                      }
                    >
                      <div
                        data-w-id="a8a92b1b-f9da-473c-5cbc-6438bac8b36f"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Zapnutie/vypnutie profilu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Máte veľa objednávok a nestíhate ich vybavovať alebo ich nestíhajú vybavovať vaši kuriéri? V administrácii jednoducho pozastavte profil, celú platformu alebo dokonca všetky platformy naraz. Po ich vypnutí môžete nastaviť ich automatické spustenie po vami zvolenom čase alebo ich kedykoľvek manuálne spustiť.
                          </p>
                          <a
                            href="images/pozastaveni_profilu.webp"
                            data-lightbox="screenshots"
                            data-title="Zapínání/vypínání profilů"
                          >
                            <img
                              src="images/pozastaveni_profilu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="dočasné pozastavení přijímání objednávek z každe platformy"
                              title="Pozastavení všech profilů na jednom místě"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 5 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 5 ? null : 5,
                        })
                      }
                    >
                      <div
                        data-w-id="78236de8-9229-30c5-4b98-cbcb34808e03"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Automatické potvrdenie objednávok
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Venujte čas tým, ktorí to ocenia - Vašim zákazníkom a nechajte potvrdzovanie objednávok na nás. Čas potvrďovania si nastavíte podľa možností kuchyne a o všetko ostatné sa postará náš Autobot. Väčšie objednávky automaticky potvrdíme s dlhším časom prípravy, alebo si sami zvolíte, koľko minút chcete ku každej objednávke pridať navyše.
                          </p>
                          <a
                            href="images/autobot.webp"
                            data-lightbox="screenshots"
                            data-title="Automatické potvrzování objednávek"
                          >
                            <img
                              src="images/autobot.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="autobot automatické potvrzení objednávek z platforem bolt, wolt, foodora"
                              title="Autobot - automatické potvrzení objednávek"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 6 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 6 ? null : 6,
                        })
                      }
                    >
                      <div
                        data-w-id="c350282e-6690-d04e-773f-206d80e5290c"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Základné štatistiky
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Každodenné vyúčtovanie už nikdy nebude problém. Prehľadne vám ukážeme, koľko ste vybavili objednávok, akým spôsobom platili zákazníci, a koľko peňazí ste vyzbierali za doručovanie. Všetky informácie je možné samozrejme posielať aj na email.
                          </p>
                          <a
                            href="images/statistiky.webp"
                            data-lightbox="screenshots"
                            data-title="Základní statistiky"
                          >
                            <img
                              src="images/statistiky.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="statistiky prodeje obratů a tržeb"
                              title="Statistika prodeje"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 7 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 7 ? null : 7,
                        })
                      }
                    >
                      <div
                        data-w-id="59958d76-e62f-2540-c8ec-5edfa86d9548"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Rozšírené štatistiky a exporty
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          V dnešnej ťažkej dobe je nutné mať prehľadné štatistiky pre správne riadenie podniku. Vo verzii RESTIA Pro sme pre Vás pripravili prehľad o výkonnosti kuchyne, efektívnosti a spozdení kuriérov, ale aj online dostupnosti všetkých Vašich profilov. Vďaka tomu vám už nikdy neklesnú tržby bez toho, aby ste neznali presný dôvod.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 8 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 8 ? null : 8,
                        })
                      }
                    >
                      <div
                        data-w-id="50330549-e16e-3a53-8ba4-9732293de8dc"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow faq-question-arrow-disabled w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Mesačná paušálna cena
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>
                              <strong>30 €</strong>
                            </div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>
                              <strong>75 €</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <section className="hero-heading-left wf-section" id="dispecink">
              <div className="container faq-container">
                <div className="hero-wrapper-3">
                  <div className="pricing-heading">
                    <h2 className="heading-2">Dispečing</h2>
                    <p className="margin-bottom-24px">
                    Dispečing slúži na riadenie rozvozu Vašich objednávok a plánovanie trás kuriérom. S riadeným dispečinkom budete riadiť rozvoz efektívne a tým doručíte oveľa viac objednávok v kratšom čase.
                    </p>
                  </div>
                  <div className="pricing-heading">
                    <img
                      src="images/dispecink.webp"
                      loading="lazy"
                      alt="dispečink pro rozvoz objednávek"
                      title="Dispečink"
                      className="service-image"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="faq-section">
              <div className="container faq-container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-description">
                      <div className="faq-question-right">
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                          Neriadený
                          </h3>
                        </div>
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                          Riadený
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 9 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 9 ? null : 9,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081a8c"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Mobilná aplikácia pre kuriérov
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Vďaka jednoduchej mobilnej aplikácii pre Android aj iOS ušetríme čas kuriérom a oni tak zvládnu doručiť viac objednávok. V aplikácii majú všetky potrebné informácie o objednávke, jedným kliknutím zavolajú zákazníkovi alebo si spustia svoju obľúbenú navigáciu Waze, Google alebo mapy.cz. Už žiadne opisovanie údajov z lístkov.{" "}
                          </p>
                          <a
                            href="images/cargo1.webp"
                            data-lightbox="screenshots"
                            data-title="Mobilní aplikace pro kurýry"
                          >
                            <img
                              src="images/cargo1.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 10 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 10 ? null : 10,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081aae"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Plánovanie trás kuriérom
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value"></div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Nechajte plánovať kuriérov, ktorú objednávku chcú viesť. Nová objednávka je vždy zobrazená každému aktívnemu kuriérovi a po prijatí jedným z nich sa ostatným skryje. Jednoduché a efektívne riadenie pri malom počte kuriérov.
                          </p>
                          <a
                            href="images/cargo2.webp"
                            data-lightbox="screenshots"
                            data-title="Plánování tras kurýrem"
                          >
                            <img
                              src="images/cargo2.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="automatické plánování trasy kurýra"
                              title="Plánování tras kurýrem"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 11 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 11 ? null : 11,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081abf"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Plánovanie trás dispečerom na mape
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Pri väčšom počte objednávok a kuriérov je efektívnejšie využiť dispečera, ktorý bude rozdeľovať objednávky. Vidí nielen všetky aktívne objednávky na prehľadnej mape, ale aj polohu jednotlivých kuriérov. Je schopný plánovať rozvoz tak, aby na seba objednávky nadväzovali a kuriér neztrácal čas dlhými prejazdmi. Týmto spôsobom riadenia nespozdíte žiadnu objednávku a vďaka tomu sa k Vám zákazník vždy rád vráti.
                          </p>
                          <a
                            href="images/planovani_tras.webp"
                            data-lightbox="screenshots"
                            data-title="Plánování tras dispečerem na mapě"
                          >
                            <img
                              src="images/planovani_tras.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="plánování trasy rozvozu"
                              title="Plánování trasy dispečerem pro kurýra"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 12 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 12 ? null : 12,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ad0"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Automatické plánovanie trás (pripravujeme)
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <div>20 €/měsíc</div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Využite moderný spôsob riadenia kuriérov. Náš Autobot spravodlivo rozdelí objednávky medzi kuriérov a nájde optimálnu cestu podľa zvolených parametrov - najkratšia cesta medzi objednávkami alebo najrýchlejšie doručenie zákazníkovi.
                          </p>
                          <a
                            href="images/automaticke_planovani_tras.webp"
                            data-lightbox="screenshots"
                            data-title="Automatické plánování tras (připravujeme)"
                          >
                            <img
                              src="images/automaticke_planovani_tras.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="automatické plánování trasy rozvozu"
                              title="Automatické plánování tras"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 13 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 13 ? null : 13,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ae1"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Počítanie dojazdových časov k zákazníkovi
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Už sa nemusíte báť telefonátov od zákazníka s otázkou, kedy mu bude doručená jeho objednávka. S naším prehľadom viete, kde presne sa kuriér vezúci objednávku nachádza a kedy ju bude doručovať. Navyše tiež máte prehľad o jeho výkonnosti alebo spozdení pri každej objednávke. Čas doručenia sa pravidelne aktualizuje každú minútu.{" "}
                          </p>
                          <a
                            href="images/pocitani_dojezdovych_casu.webp"
                            data-lightbox="screenshots"
                            data-title="Počítání dojezdových časů k zákazníkovi"
                          >
                            <img
                              src="images/pocitani_dojezdovych_casu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="výpočet doby rozvozu kurýra a jeho návratu"
                              title="Počítání dojezdových časů k zákazníkovi"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 14 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 14 ? null : 14,
                        })
                      }
                    ></div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 15 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 15 ? null : 15,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081b25"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Štatistiky rozvozu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          V štatistikách rozvozu prehľadne uvidíte výkonnosť jednotlivých kuriérov a dôvody spozdenia objednávok. Pomocou týchto štatistík dokážete lepšie riadiť náklady, kvalitu a odmeňovanie kuriérov.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 16 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 16 ? null : 16,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081b25"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Kniha jázd a vyúčtovanie kuriérov
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                          Otvorte si knihu jázd a prezrite si štatistiky každého kuriéra. Nájdete tu všetky jeho trasy, objednávky, denný nájazd kilometrov, alebo priemerný počet objednávok na trasu. Štatistiky sú dostupné aj pre kuriérov v mobilnej aplikácii, aby presne vedeli, akú tržbu majú odovzdať na konci zmeny.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 17 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 17 ? null : 17,
                        })
                      }
                    >
                      <div
                        className={`faq-accordion ${
                          this.state.accordionOpenIndex === 18 ? "open" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            accordionOpenIndex:
                              this.state.accordionOpenIndex === 18 ? null : 18,
                          })
                        }
                      >
                        <div
                          data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ae1"
                          className="faq-question faq-question-disabled"
                        >
                          <div className="faq-question-left">
                            <div className="faq-question-arrow-wrapper">
                              <div className="faq-question-arrow w-embed"></div>
                            </div>
                            <div className="faq-question-text">
                            Počet kuriérov v cene
                            </div>
                          </div>
                          <div className="faq-question-right">
                            <div className="faq-question-right-value">3</div>
                            <div className="faq-question-right-value">3</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`faq-accordion ${
                          this.state.accordionOpenIndex === 19 ? "open" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            accordionOpenIndex:
                              this.state.accordionOpenIndex === 19 ? null : 19,
                          })
                        }
                      >
                        <div
                          data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ae1"
                          className="faq-question faq-question-disabled"
                        >
                          <div className="faq-question-left">
                            <div className="faq-question-arrow-wrapper">
                              <div className="faq-question-arrow w-embed"></div>
                            </div>
                            <div className="faq-question-text">
                            Každý ďalší kuriér
                            </div>
                          </div>
                          <div className="faq-question-right">
                            <div className="faq-question-right-value">
                              <div>15 €/měsíc</div>
                            </div>
                            <div className="faq-question-right-value">
                              <div>30 €/měsíc</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081b36"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow faq-question-arrow-disabled w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Cena mesačného paušálu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>
                              <strong>60 €</strong>
                            </div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>
                              <strong>80 €</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <section
              className="hero-heading-left wf-section"
              id="vlastni-eshop"
            >
              <div className="container faq-container">
                <div className="hero-wrapper-3">
                  <div className="pricing-heading">
                    <h2 className="heading-2">Vlastný e-shop</h2>
                    <p className="margin-bottom-24px">
                    Vlastný web s e-shopom pre Vašich zákazníkov, kde si prispôsobíte vzhľad podľa Vašich prianí a neplatíte žiadne provízie z objednávok.
                    </p>
                  </div>
                  <div className="pricing-heading">
                    <img
                      src="images/web-restia.webp"
                      loading="lazy"
                      alt="vlastní eshop restaurace"
                      title="Vlastní eshop restaurace"
                      className="service-image"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="faq-section">
              <div className="container faq-container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-description">
                      <div className="faq-question-right">
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading"></h3>
                        </div>
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading"></h3>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66cf4"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                          Real-time komunikácia so zákazníkom
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <div>
                              <img
                                src="images/fa-check.webp"
                                loading="lazy"
                                alt="dostupné v tarifním plánu"
                                title="dostupná funkce"
                                className="faq-question-right-value-image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66d07"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                          Online platobná brána s Apple a Google pay
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <div>
                              <img
                                src="images/fa-check.webp"
                                loading="lazy"
                                alt="dostupné v tarifním plánu"
                                title="dostupná funkce"
                                className="faq-question-right-value-image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66caf"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                          Bonusový program, akcie a vouchery pre zákazníkov
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66ce3"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                          Vlastný vzhľad stránok s možnosťou obrázkov pre jedlá a kategórie
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="3f7a7d9b-2637-b39a-3792-7d2ba7111281"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                          Rozvozové zóny
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="3f7a7d9b-2637-b39a-3792-7d2ba7111281"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                          QR menu pre Vašich zákazníkov
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66d2b"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow faq-question-arrow-disabled w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                          Cena mesačného paušálu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>
                              <strong></strong>
                            </div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>
                              <strong>35 €</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <div className="extra-section wf-section">
              <div className="container extra-container w-container">
                <div className="extra">
                  <h2 className="heading-3">Prvé spustenie</h2>
                  <div className="w-layout-grid grid-2">
                    <div
                      id="w-node-b9067f31-9156-b701-ba3b-45a66c638867-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div id="w-node-_4598aed1-de80-3deb-a433-72b6a41611e9-24409e3c">
                      Inštalačný poplatok a školenie
                      </div>
                    </div>
                    <div
                      id="w-node-e53baccb-aad3-efb2-a30a-6bde6df2f581-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>100 €</div>
                    </div>
                    <div
                      id="w-node-eda47b89-afca-c196-06d4-bdebe9433f4b-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Pripojenie jedného profilu</div>
                    </div>
                    <div
                      id="w-node-_5226ec36-fb86-8555-727d-59a714c9af70-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>40 €</div>
                    </div>
                    <div
                      id="w-node-_9556ba17-0b28-e0cb-cde9-b601395be1c9-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Tablet do prevádzky</div>
                    </div>
                    <div
                      id="w-node-_8836d8c1-6b85-0e8c-14c2-e1d2df177d99-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>2&nbsp;00 €</div>
                    </div>
                  </div>
                </div>
                <div className="extra">
                  <h3 className="heading-3">Extra</h3>
                  <div className="w-layout-grid grid-2">
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c2-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c3-24409e3c">
                      Pripojenie Vášho webu
                      </div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c5-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>200 €</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c8-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Eshop RESTIA</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1cb-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>330 €</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1ce-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Prenájom eshopu</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1d1-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>35 €/měsíc</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <a
								href="/#product-price"
								className="button button-centered w-inline-block">
								<img
									src="images/settings.svg"
									loading="lazy"
									alt="alt_images/settings.svg"
                  title="title_images/settings.svg"
									className="image-9"
								/>
								<div className="text-block-2">Konfigurovat</div>
							</a> */}
              <Link
                to="/#product-price"
                className="button button-centered w-inline-block"
              >
                <img
                  src="images/settings.svg"
                  loading="lazy"
                  alt="konfigurátor ceny produktu"
                  title="Konfigurátor ceny"
                  className="image-9"
                />
                <div className="text-block-2">Konfigurácia</div>
              </Link>
            </div>
            <Footer />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default ProduktyView;

/* eslint-enable */
